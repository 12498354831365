<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.malfunctionConfirmation')"
  >
    <div id="outer-title">{{ $t("i18n.malfunctionConfirmation") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.picture')"
      v-model="dialogFormVisible"
      width="50%"
      :before-close="handleClose"
      destroy-on-close
    >
    <el-image :src="imgSrc"></el-image>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "EFaultConfirm",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      dialogFormVisible:false,
      imgSrc: "",
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "faultCode",
          label: "faultCode",
          align: "center",
        },
        {
          prop: "faultTime",
          label: "faultTime",
          align: "center",
        },
        {
          prop: "levels",
          label: "faultLevel",
          align: "center",
          formatter: (row) => {
            return row.levels ? t("i18n." + row.levels) : "-";
          },
        },
        {
          prop: "serviceMode",
          label: "elevatorStatus",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 220,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "ignore",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              elevatorFaultConfirm(row.id);
            },
          },
          {
            id: "2",
            label: "picture",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              state.dialogFormVisible = true;
              state.imgSrc = proxy.$defined.imgUrl + "camera/" + row.number + "/" + row.alias +".jpg";
            },
          },
        ],
      },
      total: 0,
      loading: true,
    });

    const init = async (params) => {
      state.loading = true;
      params.eleNumber = params.number;
      const { data } = await proxy.$api.rescue.errorsPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const elevatorFaultConfirm = (id) => {
      let callback = () => {
        proxy.$api.rescue.errorsDelete(id).then(() => {
          init(state.formInline);
          proxy.$defined.tip(t("i18n.ignore") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToIgnore"),
        t("i18n.ignore")
      );
    };

    return {
      ...toRefs(state),
      elevatorFaultConfirm,
      init,
    };
  },
};
</script>
